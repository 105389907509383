/* eslint-disable import/no-named-as-default-member */
import dayjs from "dayjs";
import "dayjs/locale/fr";
import duration from "dayjs/plugin/duration";
import localeData from "dayjs/plugin/localeData";
import relativeTime from "dayjs/plugin/relativeTime";

export const LOCALE = "fr";

dayjs.locale(LOCALE);
dayjs.extend(localeData);
dayjs.extend(duration);
dayjs.extend(relativeTime);

export const weekdays: string[] = dayjs.weekdays(true);

export const DATE_FORMAT = "DD MMMM YYYY";

export const getWeekday = (dayNumber: number): string => {
  return weekdays[dayNumber - 1];
};

export const formaterDate: (date: Date) => string = (date) => {
  const day: string = date.getDate().toString().padStart(2, "0");
  const month: string = (date.getMonth() + 1).toString().padStart(2, "0");
  const year: number = date.getFullYear();
  return `${day}/${month}/${year}`;
};

export default dayjs;
